<template>
  <div>
    <v-form>
      <p class="mb-3">
        Compound A has a molecular formula of
        <chemical-latex content="C6H14O\text{.}" />
        What is the Unsaturation Number (UN) or Double-Bond Equivalent (DBE)?
      </p>
      <p>
        UN/DBE:
        <calculation-input v-model="inputs.studentAnswer" class="mb-5" :disabled="!allowEditing" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI51LBA2_M1Q1a',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
};
</script>
